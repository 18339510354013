export const allowlistAddresses = [

  ["0xd61daebc28274d1feaaf51f11179cd264e4105fb",1],
  ["0x80603ed0af4921e5f00b97145fb36f436ec8dad5",1],
  ["0x247b7e245002e6b525782366dec32446f645320d",1],
  ["0x19a1c54ebfc89d09b815c610a745ed3b4ddd8c58",1],
  ["0xa3eac0016f6581ac34768c0d4b99ddcd88071c3c",1],
  ["0xbc2ae45ee0dd58b1fddf3ac6e98c8f2d3ff3591d",1],
  ["0x5b94708028129a82ea6ce11dea5112d5c93bb554",1],
  ["0xf43ea6051b65f8de25b55e65c6ef244599693123",1],
  ["0xdb4a057c4054e030e6f04619777c17cafa33cf65",1],
  ["0x0a8f6171308cfb472c627af373d09d2e3b95587e",1],
  ["0x4e5e4a07dd21e4b840052d98e113aeae5798da3d",1],
  ["0x20cd91b4ca4bc98a9df47e5cc5432dcc35a2feda",1],
  ["0xda013983cbf67b5ef173d7ef83918ec526786425",1],
  ["0xe2f824f920699f66bc9d292ed6e55420b4744f22",1],
  ["0xcf8ba110535139affcd91a27abe4441d6bb8de69",1],
  ["0xf78cd306b23031de9e739a5bcde61764e82ad5ef",1],
  ["0xcc65f2b00fce51148a2d660c1ed2511fcf67824f",1],
  ["0xfe0f49bf2e1d4e86a79190616a06fb40a6c61621",1],
  ["0x9f27f2c6f3fd1472779b2b2c58201a090604c73d",1],
  ["0xfe97d2c367c337b97fac039f2b2a052f3d10989c",1],
  ["0x364f0ad60f8d34a5643542a8623274636e6951f0",1],
  ["0xac9ba72fb61aa7c31a95df0a8b6eba6f41ef875e",1],
  ["0x9705376153eb999def24630f56fe8af703b5ad31",1],
  ["0x9f8b214bf13f62cfa5160ed135e233c9ddb95974",1],
  ["0xba740c9035ff3c24a69e0df231149c9cd12bae07",1],
  ["0x2b59fe9c163c6c7c27a32a70eb2bd8df8d3c70fa",1],
  ["0xe6c071051885b9e4fa20ae5629d8af26a2f3a32a",1],
  ["0x18ea70f91e5df7e827f7d8589fe35fee2c921e22",1],
  ["0xab168c46b6babde7a6e0787a33894683f7cbe690",1],
  ["0xbb5cef05edac921544046d3274a6ee3dd51196b1",1],
  ["0xdd3c3aa038c4a1ffe9b6e95604274cf73c9373b4",1],
  ["0x5a106f5cf9fba6af5dda6bde1b8f3bacbd532c37",1],
  ["0x85d881b9d89def03b73e741b0a3e8dd74f711d65",1],
  ["0xc5f32455e7e97564ef6e71c439708d3e12da91c5",1],
  ["0x499eb561220eb358ccbc5a72d4cdd4f5b76a2d2a",1],
  ["0xa2e1bdaa2c9a77a7d21c55dc0fbd62323e5cc2b4",1],
  ["0xd8ca604cd545f4586dad4fff2adc380b2c1675c6",1],
  ["0x01c12063b7b5b783a989a60af9f9f06534307a2d",1],
  ["0x5176759e9f05b43878723e575b20f0e17899d01f",1],
  ["0x7121e94bb6850b60bfbb9d8ec6def634d43f33ac",1],
  ["0x32790dee22bed1916303e2c3f4253fc2cfc0c417",1],
  ["0x965a22cd40409d49e18424e378e2ee26e20228e3",1],
  ["0x4ceaa0a16d0831d7d46cb72006f959a87f618a47",1],
  ["0xf01bcb0090cd0f734688ce77ae067da58e9d8005",1],
  ["0x58ca21ad4f4101a37045aef5185fd7b1567bf82d",1],
  ["0xec62e0d456c5461f16650fdd2cda6e4c337453a2",1],
  ["0x1a906725d4398bafbe03a54ee68db7a7517415cc",1],
  ["0x9b51af017fa7bb7b3ef4eb4c5b09b2dfab54f8bc",1],
  ["0x42e02fb5af30aa379314371ada1e3035967b569b",1],
  ["0x5dd1af23f43cf8371f152802f9c80b15ee63315b",1],
  ["0x00063ddb30be7bc2292583d5f143e9d6e6228440",1],
  ["0x9284266887ab43b6fb088aa0e4c6aef043bac823",1],
  ["0x377a5d7e35aa22645b8382182b88c1df6700427d",1],
  ["0x34b51ee720b2daa164c3245ee81c00b6fc72c41a",1],
  ["0xe8bca870337eb54c3ef39791922bd6fdcf76bc3f",1],
  ["0xa3e38e0b958924e078cc0605cdc4a7f6671aee84",1],
  
]